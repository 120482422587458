import React, { useContext } from 'react';
import { Context } from '../../context';
import TrustpilotCarouselFR from './fr';
import TrustpilotCarouselDE from './de';
import TrustpilotCarouselIT from './it';
import TrustpilotCarouselEN from './en';

export default function TrustpilotCarousel({ position }) {
  const { state } = useContext(Context);

  switch (state.locale) {
    case 'de':
      return <TrustpilotCarouselDE position={position} />;
    case 'it':
      return <TrustpilotCarouselIT position={position} />;
    case 'en':
      return <TrustpilotCarouselEN position={position} />;
    default:
      return <TrustpilotCarouselFR position={position} />;
  }
}
