import React, { useState, useEffect, useContext } from 'react';
import './../../styles/utils/Header.css';
import { Context } from './../../context';
import { IconLogin, IconKey, IconChevronDown } from '@tabler/icons-react';

export default function HeaderFR() {
  const { state, setState } = useContext(Context);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY >= 200);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <header
        id="bens-header"
        className={scroll ? 'is-fixed' : ''}
      >
        <div className="bens-container">
          <div className="swiss-made">
            <img
              src="https://bens-digital-change.com/images/v2/swiss_made_new.webp"
              alt="Swiss Made"
              width={234}
              height={256}
            />
          </div>
          <a
            href="https://bens-digital-change.com/"
            className="bens-logo"
          >
            <img
              src="https://bens-digital-change.com/images/logo/bens_digital_change.svg"
              alt="Ben S. Logo"
              width={132}
              height={64}
            />
          </a>

          <div className="bens-menu-account">
            <a href="https://bens-digital-change.swiss/register">
              <span>Inscription</span>
              <IconKey />
            </a>
            <a
              href="https://bens-digital-change.swiss/"
              className="is-default"
            >
              <span>Connexion</span>
              <IconLogin />
            </a>
          </div>

          <div className="bens-menu-secondary">
            <div className="bens-menu-secondary-item bens-menu-has-list is-lang">
              <div className="bens-menu">
                <img
                  src={
                    'https://flagcdn.com/20x15/' +
                    (state?.locale === 'en' ? 'gb' : state?.locale) +
                    '.png'
                  }
                  alt={state?.locale}
                  width="20"
                  height="15"
                />
                <span>{state?.locale?.toLocaleUpperCase()}</span>
                <IconChevronDown />
              </div>
              <div className="bens-menu-list">
                <a
                  className={state?.locale === 'fr' ? 'is-active' : ''}
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setState({ ...state, locale: 'fr' });
                  }}
                >
                  <img
                    src="https://flagcdn.com/20x15/fr.png"
                    alt="FR"
                    width="20"
                    height="15"
                  />
                  <span>FR</span>
                </a>
                <a
                  className={state.locale === 'en' ? 'is-active' : ''}
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setState({ ...state, locale: 'en' });
                  }}
                >
                  <img
                    src="https://flagcdn.com/20x15/gb.png"
                    alt="EN"
                    width="20"
                    height="15"
                  />
                  <span>EN</span>
                </a>
                <a
                  className={state.locale === 'de' ? 'is-active' : ''}
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setState({ ...state, locale: 'de' });
                  }}
                >
                  <img
                    src="https://flagcdn.com/20x15/de.png"
                    alt="DE"
                    width="20"
                    height="15"
                  />
                  <span>DE</span>
                </a>
                <a
                  className={state.locale === 'it' ? 'is-active' : ''}
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setState({ ...state, locale: 'it' });
                  }}
                >
                  <img
                    src="https://flagcdn.com/20x15/it.png"
                    alt="IT"
                    width="20"
                    height="15"
                  />
                  <span>IT</span>
                </a>
              </div>
            </div>

            <div className="bens-menu-secondary-item bens-menu-download">
              <div className="bens-menu">
                <span>Télécharger</span>
                <IconChevronDown />
              </div>
              <div className="bens-menu-list has-store-badge">
                <a
                  href="https://apps.apple.com/us/app/keynote/id1587182131"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-ios"
                >
                  <img
                    src="https://bens-digital-change.com/images/v2/store-custom-ios.webp"
                    alt="IOS"
                    width={200}
                    height={64}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=ch.ischange.digital_change_app.bens"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-android"
                >
                  <img
                    src="https://bens-digital-change.com/images/v2/store-custom-android.webp"
                    alt="Android"
                    width={200}
                    height={61}
                  />
                </a>
                <a
                  href="https://bens-digital-change.swiss/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="app-web"
                >
                  <img
                    src="https://bens-digital-change.com/images/v2/store-custom-web.webp"
                    alt="Web"
                    width={197}
                    height={55}
                  />
                </a>
              </div>
            </div>
          </div>

          <div
            id="bens-menu-button"
            className="is-hidden"
            onClick={() => {
              setState({ ...state, menu: !state.menu });
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={64}
              height={64}
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </div>
        </div>
      </header>
      <div className="header-back-left"></div>
      <div className="header-back-right"></div>
    </>
  );
}
