import React from 'react';
import './../../styles/components/TrustpilotCarousel.css';

export default function TrustpilotCarouselFR({ position }) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div className="trustpilot-container">
      <h3>{'Retour d’expérience de nos clients'}</h3>
      <div
        id="bens-section-06"
        style={{
          position: position || 'absolute',
          top: position === 'absolute' ? '-14rem' : 'auto',
          margin: position === 'absolute' ? 'auto' : '2rem auto 4rem auto',
        }}
      >
        {/* TrustBox widget - Carousel */}
        <div
          ref={ref}
          className="trustpilot-widget is-carousel"
          data-locale="fr"
          data-template-id="53aa8912dec7e10d38f59f36"
          data-businessunit-id="624471da9077911b7629b8db"
          data-style-height="140px"
          data-style-width="100%"
          data-theme="light"
          data-stars={5}
          data-review-languages="*"
        >
          <a
            href="https://fr.trustpilot.com/review/bens-digital-change.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
        {/* End TrustBox widget */}
      </div>
    </div>
  );
}
