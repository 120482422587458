import './styles/App.css';

import React, { useState, useEffect } from 'react';

import Header from './utils/Header';
import Menu from './utils/Menu';
import Hero from './utils/Hero';
import Footer from './utils/Footer';
import Advantages from './components/Advantages';
import Commitment from './components/Commitment';
import Solutions from './components/Solutions';
import Bens from './components/Bens';
import Free from './components/Free';
import Economy from './components/Economy';
import CurrentTheme from './components/CurrentTheme';
import Offer from './components/Offer';
// import Popup from "./components/Popup";
import config from './config';
import { ContextProvider } from './context';

function App() {
  const [promoCode, setPromoCode] = useState('');
  const specialPromoCodes = ['ETE50', 'GTE25', 'BSC50'];

  useEffect(() => {
    if (window) {
      const queryParams = new URLSearchParams(window.location.search);
      const paramName = 'promo';

      if (queryParams.has(paramName)) {
        const paramValue = queryParams.get(paramName);
        specialPromoCodes.includes(paramValue)
          ? setPromoCode(paramValue)
          : setPromoCode('');
      }
    }
    // eslint-disable-next-line
  }, []);
  return (
    <ContextProvider>
      <div
        id="app"
        style={{
          background:
            'linear-gradient(180deg, ' +
            CurrentTheme(
              specialPromoCodes.includes(promoCode) ? 'summer' : config.theme
            )['color'][0] +
            ' 0%, ' +
            CurrentTheme(
              specialPromoCodes.includes(promoCode) ? 'summer' : config.theme
            )['color'][1] +
            ' 16%)',
        }}
      >
        <Header />
        <Menu />
        {config.offer ? <Offer /> : null}
        <Hero />
        <Bens />
        <Free />
        <Economy />
        <Solutions />
        <Commitment />
        <Advantages />
        <Footer />
        {/* <Popup /> */}
      </div>
    </ContextProvider>
  );
}

export default App;
