import React, { useContext } from 'react';
import './../../styles/utils/Menu.css';
import { Context } from './../../context';
import { IconLogin, IconKey, IconChevronDown } from '@tabler/icons-react';

export default function MenuDE() {
  const { state, setState } = useContext(Context);

  return (
    <div
      id="bens-menu-mobile"
      className={state.menu ? '' : 'is-hidden'}
    >
      <div
        id="mb-shadow"
        className={state.menu ? '' : 'is-hidden'}
        onClick={() => {
          setState({ ...state, menu: !state.menu });
        }}
      />
      <div
        id="mb-wrapper"
        className={state.menu ? '' : 'is-hidden'}
      >
        <div className="mb-row">
          <a
            href="https://bens-digital-change.com/"
            className="bens-logo"
          >
            <img
              src="https://bens-digital-change.com/images/logo/bens_digital_change.svg"
              alt="Ben S. Logo"
              width={132}
              height={64}
              loading="lazy"
            />
          </a>
          <div className="swiss-made">
            <img
              src="https://bens-digital-change.com/images/v2/swiss_made_new.webp"
              alt="Swiss Made"
              width={243}
              height={256}
              loading="lazy"
            />
          </div>
        </div>

        <div className="mb-section mb-account">
          <div className="mb-item">
            <a
              href="https://bens-digital-change.swiss/register"
              className="mb-item-label"
            >
              <span>Registrierung</span>
              <IconKey />
            </a>
          </div>
          <div className="mb-item">
            <a
              href="https://bens-digital-change.swiss/"
              className="mb-item-label"
            >
              <span>Anmeldung</span>
              <IconLogin />
            </a>
          </div>
        </div>

        <div className="mb-section mb-secondary is-lang">
          <div className="bens-menu-list">
            <a
              className={state?.locale === 'fr' ? 'is-active' : ''}
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, locale: 'fr' });
              }}
            >
              <img
                src="https://flagcdn.com/20x15/fr.png"
                alt="FR"
                width="20"
                height="15"
              />
              <span>FR</span>
            </a>
            <a
              className={state.locale === 'de' ? 'is-active' : ''}
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, locale: 'de' });
              }}
            >
              <img
                src="https://flagcdn.com/20x15/de.png"
                alt="DE"
                width="20"
                height="15"
              />
              <span>DE</span>
            </a>
            <a
              className={state.locale === 'it' ? 'is-active' : ''}
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, locale: 'it' });
              }}
            >
              <img
                src="https://flagcdn.com/20x15/it.png"
                alt="IT"
                width="20"
                height="15"
              />
              <span>IT</span>
            </a>
            <a
              className={state.locale === 'en' ? 'is-active' : ''}
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, locale: 'en' });
              }}
            >
              <img
                src="https://flagcdn.com/20x15/gb.png"
                alt="EN"
                width="20"
                height="15"
              />
              <span>EN</span>
            </a>
          </div>
        </div>

        <div className="mb-section mb-secondary mb-download">
          <div className="mb-item mb-has-list">
            <div className="mb-item-label">
              <span>Herunterladen</span>
              <IconChevronDown />
            </div>
            <div className="mb-item-list is-show has-store-badge">
              <a
                href="https://apps.apple.com/us/app/keynote/id1587182131"
                target="_blank"
                rel="noopener noreferrer"
                className="app-ios"
              >
                <img
                  src="https://bens-digital-change.com/images/v2/store-custom-ios.webp"
                  alt="IOS"
                  loading="lazy"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=ch.ischange.digital_change_app.bens"
                target="_blank"
                rel="noopener noreferrer"
                className="app-android"
              >
                <img
                  src="https://bens-digital-change.com/images/v2/store-custom-android.webp"
                  alt="Android"
                  loading="lazy"
                />
              </a>
              <a
                href="https://bens-digital-change.swiss/"
                target="_blank"
                rel="noopener noreferrer"
                className="app-web"
              >
                <img
                  src="https://bens-digital-change.com/images/v2/store-custom-web.webp"
                  alt="Web"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
