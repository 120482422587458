import React, { useState, useEffect } from 'react';
import './../../styles/components/Bens.css';
import {
  IconUsersGroup,
  IconPlayerPlay,
  IconTransfer,
  IconClock24,
} from '@tabler/icons-react';

export default function BensFR() {
  const [promoCode, setPromoCode] = useState('');
  const specialPromoCodes = ['ETE50', 'GTE25', 'RUGBY75', 'BSC50'];

  useEffect(() => {
    if (window) {
      const queryParams = new URLSearchParams(window.location.search);
      const paramName = 'promo';

      if (queryParams.has(paramName)) {
        const paramValue = queryParams.get(paramName);
        specialPromoCodes.includes(paramValue)
          ? setPromoCode(paramValue)
          : setPromoCode('');
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="bens">
      <div className="bens-container">
        <h2 className="bens-title">Ben S. Digital Change</h2>
        <p className="bens-text">
          Ben S. Digital Change est un intermédiaire financier spécialisé dans
          le change Franc Suisses – Euros.
        </p>
        <p className="bens-text">
          C’est une marque commerciale de FANIRO SARL, entreprise reconnue
          depuis <b>57 ans</b> à Genève.
        </p>
        <p className="bens-text">
          Elle est <b>membre de l’ARIF</b>, organisme d’autorégulation agréé par
          la FINMA.
        </p>
      </div>
      <div className="bens-container has-circle">
        <div className="bens-item">
          <span className="order">{'1.'}</span>
          <IconUsersGroup className="bens-img" />
          <h3 className="bens-title">Créer votre compte client</h3>
          <p className="bens-desc">
            L’inscription est gratuite, sécurisée et sans aucuns frais
            d’inscription
          </p>
        </div>
        {specialPromoCodes.includes(promoCode) ? (
          <div className="bens-item">
            <span className="order">{'2.'}</span>
            <IconPlayerPlay className="bens-img" />
            <h3 className="bens-title">Initiez une transaction de change</h3>
            <p className="bens-desc">
              Depuis votre espace client, réalisez une demande de transaction
            </p>
          </div>
        ) : null}
        <div className="bens-item">
          <span className="order">
            {specialPromoCodes.includes(promoCode) ? '3.' : '2.'}
          </span>
          <IconTransfer className="bens-img" />
          <h3 className="bens-title">Transférez vos fonds</h3>
          <p className="bens-desc">
            Depuis votre E-banking vous envoyez les fonds à changer sans aucun
            frais de transaction
          </p>
        </div>
        <div className="bens-item">
          <span className="order">
            {specialPromoCodes.includes(promoCode) ? '4.' : '3.'}
          </span>
          <IconClock24 className="bens-img" />
          <h3 className="bens-title"> Conversion de vos fonds en +/- 24 h</h3>
          <p className="bens-desc">
            Vos fonds seront convertis et arriveront sur votre compte de
            destination généralement sous 24 h.
          </p>
        </div>
      </div>
    </div>
  );
}
