const themes = {
  default: 'default',
  noel: 'noel',
  halloween: 'halloween',
  easter: 'easter',
  summer: 'summer',
};

const config = {
  theme: themes.default,
  offer: false,
  promotion: true,
  apiUrl: 'https://bens-digital-change.com/',
  appUrl: 'https://actualite.bens-digital-change.com',
  version: 'v2.5.0',
};

export default config;
