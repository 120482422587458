import React from 'react';
import './../../styles/components/Solutions.css';
import CurrentTheme from './../CurrentTheme';
import config from '../../config';

export default function SolutionsIT() {
  return (
    <div className="solutions">
      <div className="bens-container">
        <div className="solutions-top">
          <div className="box-col">
            <div className="media-group">
              <a
                className="media-item"
                href="https://bens-digital-change.com/changez-votre-salaire"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Cambia il tuo stipendio</h4>
                <img
                  src="/img/sol-01.webp"
                  alt="Sol 01"
                  loading="lazy"
                  width={340}
                  height={341}
                />
              </a>

              <a
                className="media-item"
                href="https://bens-digital-change.com/transferts-de-capital-vieillesse"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Trasferimenti di capitale di vecchiaia Risparmio</h4>
                <img
                  src="/img/sol-02.webp"
                  alt="Sol 02"
                  loading="lazy"
                  width={340}
                  height={351}
                />
              </a>
            </div>

            <div className="media-group">
              <a
                className="media-item"
                href="https://bens-digital-change.com/transactions-immobilieres"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Operazioni immobiliari estere</h4>
                <img
                  src="/img/sol-03.webp"
                  alt="Sol 03"
                  loading="lazy"
                  width={358}
                  height={351}
                />
              </a>

              <a
                className="media-item"
                href="https://bens-digital-change.com/achat-et-vente-a-linternational"
                target="_blank"
                rel="noopener noreferrer"
              >
                <h4>Acquisto / vendita internazionale</h4>
                <img
                  src="/img/sol-04.webp"
                  alt="Sol 04"
                  loading="lazy"
                  width={351}
                  height={363}
                />
              </a>
            </div>
          </div>
          <div className="box-col solutions-top-right">
            <h2>
              La nostra soluzione di scambio digitale si adatta alle tue
              esigenze
            </h2>
            <a
              href="https://bens-digital-change.swiss/register"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-green"
            >
              Iscrizione gratuita e non vincolante
            </a>
            <a
              href="https://calendly.com/bens_digital/demande-d-assistance"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contatta i nostri consulenti
            </a>
          </div>
        </div>

        <div className="section-08-box solutions-bottom">
          <div className="box-col">
            <img
              src="https://bens-digital-change.com/images/v2/get-mobile-back.webp"
              alt="Get Mobile"
              className="box-img"
              loading="lazy"
              width={733}
              height={671}
            />
          </div>
          <div className="box-col">
            <h2 className="box-title">
              La nostra soluzione è disponibile sul cellulare
            </h2>
            <img
              src="/img/bens-title-dark.svg"
              alt="Bens"
              className="box-desc-img"
              loading="lazy"
              width={399}
              height={51}
            />
            <p className="box-long-desc">
              Effettua tutte le tue transazioni in valuta estera sulla nostra
              applicazione disponibile su Android e IOS. Vuoi cambiare la tua
              valuta in un momento specifico?
            </p>
            <p className="box-long-desc">
              La nostra applicazione ti offre la possibilità di creare un avviso
              per avvisarti quando il tasso di cambio raggiunge quello da te Che
              volete.
            </p>
            <a
              href="https://bens-digital-change.ch/download-mobile-app"
              className="box-store"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://bens-digital-change.com/images/v2/bens-stores.webp"
                alt="Bens Store"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
      <div
        className="solutions-back"
        style={{
          background:
            'linear-gradient(180deg, ' +
            CurrentTheme(config.theme)['color'][0] +
            ' 0%, ' +
            CurrentTheme(config.theme)['color'][1] +
            ' 100%)',
        }}
      ></div>
    </div>
  );
}
