import React, { useEffect } from 'react';
import { CountUp } from 'countup.js';
import './../../styles/components/Stats.css';

function styleDigits(field, number) {
  field.innerHTML = '';

  let formattedNumber = new Intl.NumberFormat('fr-CH').format(number);

  field.classList.add('finished');

  formattedNumber
    .replace(/\s/g, "'")
    .split('')
    .forEach((digit) => {
      const digitSpan = document.createElement('span');
      digitSpan.classList.add('stats-value');
      digitSpan.textContent = digit;
      field.appendChild(digitSpan);
    });
}

function stats(stat) {
  const countUp = new CountUp(stat, stat.dataset.value, {
    separator: "'",
    decimal: ',',
    prefix: '',
    suffix: '',
    startVal: 0,
    duration: 2,
    useEasing: true,
    onCompleteCallback: () => styleDigits(stat, stat.dataset.value),
  });
  countUp.start();
}

export const StatsFr = () => {
  useEffect(() => {
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          stats(entry.target);
          observer.unobserve(entry.target);
        }
      });
    };

    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    const statsElements = document.querySelectorAll('.stats-field');
    statsElements.forEach((elem) => observer.observe(elem));
  }, []);

  return (
    <div id="stats">
      <div className="bens-container stats-boxes">
        <div className="stats-box">
          <div className="stats-box-icon">
            <img
              src="https://bens-digital-change.com/images/stats/stats-rating.png"
              alt="Plus de 25'000 clients satisfaits"
              width={512}
              height={512}
            />
          </div>
          <div className="stats-box-content">
            <p>Plus de</p>
            <h3
              id="stats-clients"
              className="stats-field"
              data-value={25000}
            >
              {"25'000"}
            </h3>
            <p>clients satisfaits</p>
          </div>
        </div>
        <div className="stats-box">
          <div className="stats-box-icon">
            <img
              src="https://bens-digital-change.com/images/stats/stats-export.png"
              alt="Plus de 190\'000 transactions effectuées"
              width={512}
              height={512}
            />
          </div>
          <div className="stats-box-content">
            <p>Plus de</p>
            <h3
              id="stats-transactions"
              className="stats-field"
              data-value={190000}
            >
              {"190'000"}
            </h3>
            <p>transactions effectuées</p>
          </div>
        </div>
        <div className="stats-box">
          <div className="stats-box-icon">
            <img
              src="https://bens-digital-change.com/images/stats/stats-save-time.png"
              alt="Plus de 2,5 millions d'EUR économisés par nos clients"
              width={512}
              height={512}
            />
          </div>
          <div className="stats-box-content">
            <p>Plus de</p>
            <h3>
              EUR
              <span
                id="stats-economies"
                className="stats-field"
                data-value="2.5"
              >
                {'2,5'}
              </span>
              Millions
            </h3>
            <p>économisés par nos clients</p>
          </div>
        </div>
      </div>
      <div className="bens-container stats-bottom">
        <p>{'*basé sur les chiffres de'}</p>
        <img
          src="https://bens-digital-change.com/images/logo/bens_solutions_de_change.png"
          alt="Ben S. Solutions de Change Logo"
          width="380"
          height="229"
        />
        <p>{'en 2023'}</p>
      </div>
    </div>
  );
};
