import './../../styles/components/Free.css';
import './../../styles/components/TrustpilotCarousel.css';
import React, { useState, useEffect } from 'react';

export default function Free() {
  const [promoCode, setPromoCode] = useState('');
  const specialPromoCodes = ['ETE50', 'GTE25', 'RUGBY75', 'BSC50'];

  useEffect(() => {
    if (window) {
      const queryParams = new URLSearchParams(window.location.search);
      const paramName = 'promo';

      if (queryParams.has(paramName)) {
        const paramValue = queryParams.get(paramName);
        specialPromoCodes.includes(paramValue)
          ? setPromoCode(paramValue)
          : setPromoCode('');
      }
    }
    // eslint-disable-next-line
  }, []);
  return specialPromoCodes.includes(promoCode) ? (
    <>
      <div className="free">
        <div className="bens-container">
          <div className="bens-row">
            <div className="bens-col">
              <span className="bens-section-02-item">“</span>
            </div>
            <div className="bens-col">
              <div className="bens-section-02-title">
                <h2>
                  Aucuns frais <b>d’inscription</b>, aucuns frais{' '}
                  <b>de transaction</b>, aucuns frais{' '}
                  <b>de tenue de compte, ni de clôture</b>
                </h2>
              </div>
            </div>
            <div className="bens-col">
              <div className="bens-section-02-item">
                <span>”</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bens-container">
        <div className="bens-row mx-auto">
          <div className="bens-col">
            <a
              href="https://bens-digital-change.swiss/register"
              target="_blank"
              rel="noopener noreferrer"
              className="free-cta"
            >
              {'Commencer à économiser'}
            </a>
          </div>
        </div>
      </div>
    </>
  ) : null;
}
