import './../../styles/components/Free.css';
import React, { useState, useEffect } from 'react';
import TrustpilotCarousel from './../TrustpilotCarousel';

export default function Economy() {
  const [promoCode, setPromoCode] = useState('');
  const specialPromoCodes = ['ETE50', 'GTE25', 'RUGBY75', 'BSC50'];

  useEffect(() => {
    if (window) {
      const queryParams = new URLSearchParams(window.location.search);
      const paramName = 'promo';

      if (queryParams.has(paramName)) {
        const paramValue = queryParams.get(paramName);
        specialPromoCodes.includes(paramValue)
          ? setPromoCode(paramValue)
          : setPromoCode('');
      }
    }
    // eslint-disable-next-line
  }, []);
  return specialPromoCodes.includes(promoCode) ? (
    <>
      <div className="bens-container">
        <TrustpilotCarousel position="relative" />
      </div>
      <div className="free">
        <div className="bens-container">
          <div className="bens-row">
            <div className="bens-col">
              <span className="bens-section-02-item">“</span>
            </div>
            <div className="bens-col">
              <div className="bens-section-02-title">
                <h2>
                  Un client qui change{' '}
                  <span className="text-is-red">6'000.- francs suisses</span>{' '}
                  par mois <span className="text-is-red">économise</span> en
                  moyenne <span className="text-is-red">900.- euros</span> par
                  année
                </h2>
              </div>
            </div>
            <div className="bens-col">
              <div className="bens-section-02-item">
                <span>”</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bens-container">
        <div className="bens-row mx-auto">
          <div className="bens-col">
            <a
              href="https://bens-digital-change.swiss/register"
              target="_blank"
              rel="noopener noreferrer"
              className="cta-button cta-button-primary"
            >
              {'Commencer l’inscription'}
            </a>
            <a
              href="https://fr.trustpilot.com/review/bens-digital-change.com"
              target="_blank"
              rel="noopener noreferrer"
              className="cta-button cta-button-secondary"
            >
              {'Lire plus d’avis'}
            </a>
          </div>
        </div>
      </div>
    </>
  ) : null;
}
