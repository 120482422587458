import React, { useState, useEffect } from 'react';
import './../../styles/components/Advantages.css';
import CurrentTheme from './../CurrentTheme';
import config from '../../config';

export default function AdvantagesFR() {
  const [promoCode, setPromoCode] = useState('');
  const specialPromoCodes = ['ETE50', 'GTE25', 'BSC50'];

  useEffect(() => {
    if (window) {
      const queryParams = new URLSearchParams(window.location.search);
      const paramName = 'promo';

      if (queryParams.has(paramName)) {
        const paramValue = queryParams.get(paramName);
        specialPromoCodes.includes(paramValue)
          ? setPromoCode(paramValue)
          : setPromoCode('');
      }
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div
      className="advantages"
      style={{
        background:
          'linear-gradient(0deg, ' +
          CurrentTheme(
            specialPromoCodes.includes(promoCode) ? 'summer' : config.theme
          )['color'][0] +
          ' 0%, ' +
          CurrentTheme(
            specialPromoCodes.includes(promoCode) ? 'summer' : config.theme
          )['color'][1] +
          ' 50%)',
      }}
    >
      <div className="bens-container">
        <h2 className="box-title">
          {'Les avantages à changer ses devises en ligne'}
        </h2>
        <div className="box-container">
          <div className="box-item">
            <img
              src="/img/adv-01.webp"
              alt="Adv 01"
              loading="lazy"
              width={176}
              height={158}
            />
            <h3>La flexibilité</h3>
            <p>
              Pas de lieu spécifique ! Aucun horaire ! Pour changer ses devises
              en ligne, il est possible de le faire peu importe l’endroit où
              nous nous situons. Il suffit d’une connexion internet, d’un
              smartphone, et le tour est joué.
            </p>
          </div>

          <div className="box-item">
            <img
              src="/img/adv-02.webp"
              alt="Adv 02"
              loading="lazy"
              width={173}
              height={184}
            />
            <h3>Automatiser ses transactions</h3>
            <p>
              Des transactions régulières ? Besoin de convertir votre salaire ?
              Pas de soucis. Placez votre ordre de conversion et automatisez le.
              Chaque mois, dès réception de votre argent, nous prenons en charge
              le change de votre salaire et nous vous le transférons
              immédiatement sur le compte de destination.
            </p>
          </div>

          <div className="box-item">
            <img
              src="/img/adv-03.webp"
              alt="Adv 03"
              loading="lazy"
              width={158}
              height={177}
            />
            <h3>Plus de sécurité</h3>
            <p>
              Avec le change digital, il n’existe plus de risque (vol, perte).
              Une couverture d’assurance auprès d’une compagnie majeure en
              Suisse est par ailleurs contractée pour prévenir tout risque
              éventuel.
            </p>
          </div>

          <div className="box-item">
            <img
              src="/img/adv-04.webp"
              alt="Adv 04"
              loading="lazy"
              width={153}
              height={161}
            />
            <h3>Les économies</h3>
            <p>Réaliser des économies est fondamental pour vous ?</p>
            <p>
              Avec le change en ligne, profitez d’un taux légèrement plus
              avantageux comparé aux bureaux de change physiques.
            </p>
          </div>
        </div>
        {specialPromoCodes.includes(promoCode) && (
          <a
            href="https://bens-digital-change.swiss/register"
            className="cta-button"
            target="_blank"
            rel="noreferrer noopener"
          >
            {'Inscription gratuite et sécurisée'}
          </a>
        )}
      </div>
    </div>
  );
}
