import React, { useState } from 'react';
import './../../styles/utils/Hero.css';
import Calculator from './../../components/Calculator';
import TrustpilotWidget from './../../components/TrustpilotWidget';
import CurrentTheme from './../../components/CurrentTheme';
import YouTube from 'react-youtube';
import config from '../../config';

export default function HeroEN() {
  const [visible, setVisible] = useState(false);

  const handleBtnOpen = () => {
    setVisible(true);
  };

  const handleBtnClose = () => {
    setVisible(false);
  };

  const handleWrapper = () => {
    setVisible(false);
  };

  const onPlayerReady = (event) => {
    event.target.playVideo();
  };
  const destroyVideo = (event) => {
    event.target.destroyVideo();
  };

  return (
    <div id="bens-hero">
      <div className="bens-container">
        {config.promotion ? (
          <div className="hero-col is-top-col">
            <div className="hero-col-box">
              <h2 className="is-bigger">10 CHF Free</h2>
              <h2>on your first transaction</h2>
            </div>
            <div className="hero-col-box is-small has-promotion">
              <div className="is-promotion reverse">
                <h3>Promotion valid until 31/05/2023</h3>
                <p>
                  *Offer valid for any new customer for any transaction of an
                  amount equal to or greater than CHF 500
                </p>
                <p className="is-bigger">With promo code</p>
                <span>FIRST</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="hero-col">
            <div className="hero-col-box has-not-promotion">
              <h1>
                Easily change your Swiss Francs into Euros Thanks to our online
                platform at a very attractive rate
              </h1>
            </div>
          </div>
        )}

        <div className="hero-col">
          {config.theme === 'default' ? (
            <div className="hero-col-box">
              <div
                className="has-cover-filter"
                style={{
                  background:
                    'linear-gradient(135deg, ' +
                    CurrentTheme(config.theme)['color'][0] +
                    ' 0%, ' +
                    CurrentTheme(config.theme)['color'][1] +
                    ' 100%)',
                }}
              ></div>
              <div className="hero-box-container">
                <div className="hero-row">
                  <h2 className="hero-title">
                    A foreign exchange solution that meets your personal goals
                  </h2>
                  <div className="hero-video">
                    <h3 className="video-title">How does our service work :</h3>
                    <div
                      id="video-action"
                      onClick={handleBtnOpen}
                    >
                      <img
                        src={'/img/video-cover.jpg?' + config.version}
                        alt="Ben S. Digital Change"
                        className="caption-img"
                        width={331}
                        height={185}
                        loading="lazy"
                      />
                    </div>
                    {visible ? (
                      <div
                        id="video-wrapper"
                        onClick={handleWrapper}
                        className={visible ? 'is-show' : ''}
                      >
                        <YouTube
                          id="iframe"
                          videoId="p6_RdMZBgO8"
                          opts={{
                            playsinline: 0,
                            autoplay: 1,
                            origin: window.location.origin,
                          }}
                          onReady={onPlayerReady}
                          onEnd={destroyVideo}
                        />
                        <div
                          id="video-close-btn"
                          onClick={handleBtnClose}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                          </svg>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="hero-partner">
                    <h5 className="partner-title">
                      The Multichannel Solution with
                    </h5>
                    <img
                      className="partner-img"
                      src="https://bens-digital-change.com/images/logo/bens_shop_change.svg"
                      alt="Ben S. Shop Change"
                      width={100}
                      height={67}
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="hero-row">
                  <img
                    className="hero-bens-img"
                    src="https://bens-digital-change.com/images/v2/char_bens.webp"
                    alt="The Ben S."
                    width={245}
                    height={556}
                    loading="lazy"
                  />
                </div>
              </div>

              <div className="hero-action">
                <a
                  href="https://bens-digital-change.swiss/register"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  CREATE YOUR CUSTOMER AREA
                </a>
              </div>
            </div>
          ) : (
            <div
              className="hero-col-box has-cover"
              style={{
                backgroundImage:
                  'url(' + CurrentTheme(config.theme)['cover'] + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div
                className="has-cover-filter"
                style={{
                  background:
                    'linear-gradient(135deg, ' +
                    CurrentTheme(config.theme)['color'][0] +
                    ' 0%, ' +
                    CurrentTheme(config.theme)['color'][1] +
                    ' 100%)',
                }}
              ></div>

              <div className="hero-box-container">
                <div className="hero-row">
                  <img
                    src={CurrentTheme(config.theme)['cover']}
                    alt="Cover"
                    className="hero-media"
                    loading="lazy"
                    width={1170}
                    height={629}
                  />
                </div>
              </div>

              <div className="hero-action">
                <a
                  href="https://bens-digital-change.swiss/register"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  CREATE YOUR CUSTOMER AREA
                </a>
              </div>
            </div>
          )}

          <div className="hero-col-box is-small has-calculator">
            <Calculator></Calculator>
            <TrustpilotWidget></TrustpilotWidget>
            <a
              href="https://bens-digital-change.com"
              target="_blank"
              rel="noopener noreferrer"
              className="hero-action-btn"
            >
              {'Learn more'}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
